@import url(https://fonts.google.com/specimen/Inria+Serif?query=Inria);
@import url(https://fonts.google.com/specimen/Roboto+Slab);
@import url(https://fonts.google.com/specimen/DM+Serif+Display);
@import url(https://fonts.google.com/noto/specimen/Noto+Sans+Georgian);

.App {
  text-align: center;
  font-family:"inria" ;
}

fontNormal {
  font-family: -apple-system, BlinkMacSystemFont, 'Inria Serif';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 1000px;
    width: 100%;
    position: fixed;
    letter-spacing: .21rem;
     overflow:hidden; 
}

@font-face {
  font-family: 'Inria';
  font-style: normal;
  font-weight: normal;
  src: local('Inria'), url('./fonts/InriaSerif-Regular.ttf') format('truetype');
  letter-spacing: .21rem;

  font-family: "serif";
  src:url("https://fonts.google.com/specimen/DM+Serif+Display");

  font-family: "sans-serif";
  src:url("https://fonts.google.com/noto/specimen/Noto+Sans+Georgian");
}

  @font-face {
    font-family: 'InriaBold';
    font-style: normal; 
    font-weight: normal;
    src: local('InriaBold'), url("./fonts/InriaSerif-Bold.ttf") format('truetype');
  }
  
@font-face{
  font-family: 'InriaLight';
  font-style: normal;
  font-weight: normal;
  src: local('InriaLight'), url('./fonts/InriaSerif-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Luminari';
  font-style: normal;
  font-weight: normal;
  src: local('Luminari'), url('./fonts/Luminari-Regular.woff') format('woff');
  }

.fontNormal {
  font-family: "Inria";
 }

 .fontBold {
  font-family: "InriaBold";
 }

 .fontLight {
  font-family: "InriaLight";
 }
 
.card {
  width: "";
  perspective: 999px;
} 
.card__inner { 
  transition: transform 1s;
  transform-style: preserve-3d;
  cursor: pointer;
}
.card__inner.is-flipped {
  transform: rotateY(180deg);
}
.card__face {
  color: #00000000;
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
  border-radius: 2px;
}
.card__face--front {
  transform: none;
}

.card__face--back {
 
  transform: rotateY(180deg);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

.background {
  width: 100%;
  position: absolute;
  margin-top: 700px;
  z-index: -1;
  /* opacity: .8; */
}

body {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.subcontainer {
  position: absolute;
  margin: 0 auto;
  margin-top: 900px;
  box-shadow: 2px 2px 5px white;
  border-radius: 5px;
  background-color: white;
  display: flex;
  min-width: 45vw;
  min-height: 80vh;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  justify-content: center;
}

.picture {
  width: 40vw;
  opacity: 1;
  margin-bottom: 30px;
}


input:focus, textarea:focus, select:focus {
  outline: 2px solid rgba(68, 68, 68, 0.6);
  
  fill: none;
  background: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  box-shadow: 0 0 0px 1000px white inset;
  -webkit-text-fill-color: #282c34 !important;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 px black,0 0 0px 1000px white inset;
  box-shadow: 0 0 0 px black,0 0 0px 1000px white inset;
}

input:-webkit-autofill:hover, input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  box-shadow: 0 0 0px 1000px white inset;
}


.hover-img:hover {
  opacity: .99;
  transform: scale(1.02);
  filter: contrast(1);
  border-radius: 11px;
  
  transition: all 0.3s ease-out;
}
.hover-img:active {
  opacity: .92;
  transform: scale(1.02);
  filter: blur(1px);
  filter: contrast(.89);
  border-radius: 11px;
  transition: all 0.3s ease-out;
}
.hover-img {
  opacity: 1;
  transition: all 0.3s ease-out;
  filter: contrast(.89);
}

.hover-img-ico:hover {
  opacity: .99;
  transform: scale(1.12);
  filter: contrast(1);
  transition: all 0.3s ease-out;
}
.hover-img-ico:active {
  opacity: .92;
  transform: scale(1.02);
  filter: blur(1px);
  filter: contrast(.89);
  border-radius: 11px;
  transition: all 0.3s ease-out;
}
.hover-img-ico {
  opacity: 1;
  cursor: pointer;
  transition: all 0.3s ease-out;
  filter: contrast(.89);
}

.hover-btn:hover {
  opacity: .99;
 
  filter: contrast(1.2);
  border-radius: 11px;
  
  transition: all 0.5s ease-in-out;
}
.hover-btn:active {
  opacity: .82;
  
  filter: contrast(1);
  border-radius: 11px;
  transition: all 0.5s ease-in-out;
}
.hover-btn {
  opacity: 1;
  transition: all 0.5s ease-in-out;
  /* filter: contrast(.89); */
}

.hover-btn-highlight:hover {
  opacity: 1;
  scale: 1.04;
  
  background-color: rgba(245, 245, 245, 0.068);
  filter: contrast(1.2) hue-rotate(-49deg);
  border-radius: 11px;
  
  transition: all 0.5s ease-in-out;
}
.hover-btn-highlight:active {
  opacity: .82;
  
  filter: contrast(1);
  border-radius: 11px;
  transition: all 0.5s ease-in-out;
}
.hover-btn-highlight {
  opacity: 1;
  transition: all 0.5s ease-in-out;
  /* filter: contrast(.89); */
}

.hover-arr:hover {
  opacity: .29;
  transform: scale(1.12);
  transition: all 0.3s ease-out;
}
.hover-arr:active {
  opacity: .92;
  transform: scale(1.02);
  transition: all 0.3s ease-out;
}
.hover-arr {
  opacity: 1;
  transition: all 0.3s ease-out;
}

.hover-grey {
  opacity: 1;
  transition: all 0.8s ease-out;
}
.hover-grey:active {
  opacity: .7;
  scale: .85;
  -webkit-mask-image: linear-gradient( black, black, black, transparent);
  mask-image: linear-gradient(black, black, black, transparent);
  mix-blend-mode: luminosity;
  transition: all 0.8s ease-out;
}
.hover-grey:hover {
  opacity: .7;

  mix-blend-mode: luminosity;
  transition: all 0.8s ease-out;
}


.picturegallery {
  display: "flex";
  flex-direction: "row";
  justify-content: center;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
}

.gallerypic {
  cursor: pointer;
  width: 120px;
  margin: 10px;
  height: 120px;
  opacity: 1;
  z-index: 5;
}
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}
.diapicboxa {
  position: relative;
  display: flex;
  width: 25%;
  margin: 0 auto;
  height: 350px;
  justify-content: space-between;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 15px;
  padding: 20px;
  border: 1px solid #999;
  flex-direction: column;
}
.popupCard {
  position: relative;
  display: flex;
  width: 25%;
  margin: 0 auto;
  height: 350px;
  justify-content: space-between;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 15px;
  padding: 20px;
  border: 1px solid #999;
  flex-direction: column;
}
.scroller, .scroller2 {
  scrollbar-width: thin; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer 10+ */
  overflow-y: auto; /* Add the ability to scroll */
  
}

/* Style the scrollbar for Chrome, Safari, and Opera */
.scroller::-webkit-scrollbar, .scroller2::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
  
}

.scroller::-webkit-scrollbar-track, .scroller2::-webkit-scrollbar-track {
  background: transparent; /* Make the track transparent */
  
}

.scroller::-webkit-scrollbar-thumb, .scroller2::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.5); /* Color and transparency of the thumb */
  border-radius: 6px; /* Roundness of the scrollbar thumb, adjust as needed */

}

/* Style the scrollbar for IE, Edge, and Firefox */
.scroller, .scroller2 {
  scrollbar-color: rgba(128, 128, 128, 0.5) transparent; /* Thumb and track color */
}


/* Adjustments for .scroller2 specifics */
.scroller2::-webkit-scrollbar {
  width: 9px; /* Adjust width for .scroller2 specifically */
  height: 9px;
}

.ql-syntax {
  white-space: pre-wrap;      /* Allows the text to wrap */
  word-break: break-word;     /* Breaks long words to prevent overflow */
}

.scroller2::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.7); /* Adjust color/transparency for .scroller2 */
  border-radius: 6px; 
}

.scroller2::-webkit-scrollbar-corner {
  background-color: transparent; /* Make the corner transparent */
}

.titleMP{
  color:"red"
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.video-js {
  width: 100% !important;

}

.carousel-items {
  display: flex;
  /* Apply a transition effect for smooth sliding and fading */
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
}

/* Styling for when carousel is animating to the left */
.carousel-container.animating-left .carousel-items {
  /* Use slideInFromLeft for the specific animation when moving left */
  animation: slideInFromLeft 0.5s ease-out forwards;
}

/* Styling for when carousel is animating to the right */
.carousel-container.animating-right .carousel-items {
  /* Use slideInFromRight for the specific animation when moving right */
  animation: slideInFromRight 0.5s ease-out forwards;
}

.carousel-container {
  display: flex;
  flex-wrap: nowrap; /* Prevent wraparound */
  overflow: hidden; /* Hide the overflow */
  /* Ensure the container itself has a transition for smoothness if needed */
  transition: transform 0.8s ease-out;
}

.menu-bubble {
  background-color: #333; /* Dark background */
  color: white;
  padding: 12px;
  border-radius: 8px;
  position: relative;
  margin: 2px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.3);
}

.menu-bubble .tail {
  position: absolute;
  top: -8px; /* Adjust as needed */
  width: 20px;
  height: 20px;
  background-color: #333;
  transform: rotate(45deg);
  clip-path: polygon(0 0, 0% 100%, 100% 0); /* Creates the triangle shape */
}

.hover {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease; 
}

hovering:hover .hover {
  visibility: visible;
  opacity: 1;
}

/* Style for the container */
.hover-container {
  position: relative;
  overflow: visible;
  display: inline-block; /* Keeps the container size to the content */
}

.div{
  color: white; background:none; font-family: "Inria" ; text-align:left; mix-blend-mode: luminosity;font-size: calc(0.75rem + 0.35vw);
}

/* Initially hide the div */
.hover-div {
  display: none !important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  transition:all 0.5 ease-in-out; 
  /* Add other styling as needed */
}

/* Show the div on hover */
.hover-container:hover .hover-div {
  display: block !important;
}

/* Style for the container */
.hover-containerInt {
  position: relative;
  display: inline-block; /* Keeps the container size to the content */
}

/* Initially hide the div */
.hover-divInt {
  display: none;
  
  position: absolute;
  top: 0;
  left: 0;
  
  transition:all 0.3s ease; 
  /* Add other styling as needed */
}

/* Show the div on hover */
.hover-containerInt:hover .hover-divInt {
  display: block;
}

.app-icon {
  width:35px;
  background: rgba(128, 128, 128, 0.12); 
  padding: 6px; 
  border-radius:14px;
}

.banner-fade {
  width: 100%;
  height: 30%;
  object-fit: cover;
  padding: 0px 1px;
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), black 10%, black 90%, rgba(0, 0, 0, 0));
  -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), black 10%, black 90%, rgba(0, 0, 0, 0));
  }

hr {
  border: none;
    height: calc(.25rem + 0.15vw);
    width:100%;
   
    margin-top: .92rem;
    margin-bottom: .92rem;
    background-image: url('./pics/hr.webp');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: contain;
    opacity: .88;
}